<template>
  <GlobalWindow
    :title="title"
    width="50%"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="房源类型" prop="enableSell">
        <el-radio-group v-model="form.enableSell" @change="changeEnableSell">
          <el-radio :label="0">出租</el-radio>
          <el-radio :label="1">出售</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="城市" prop="cityId">
        <el-select v-model="form.cityId" placeholder="请选择">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.cityName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房屋类型" prop="houseType">
        <el-select v-model="form.houseType" placeholder="请选择" @change="changeHouseType">
          <el-option
            v-for="item in types"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="我是" prop="rentType" v-if="form.enableSell === 0">
        <el-radio-group v-model="form.rentType" @change="changeType">
          <el-radio :label="0">业主</el-radio>
          <el-radio :label="1">室友</el-radio>
          <el-radio :label="2">转租</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="小区位置" prop="address">
        <span style="font-size: 13px; color: black;" v-if="form.title">{{form.title}}</span>
        <el-button @click="$refs.ref.openDialog()">选择位置</el-button>
      </el-form-item>
      <el-form-item label="房源具体楼栋信息" prop="roomV">
        <div style="width: 100%; display: flex; align-items: center">
          <el-input v-model="form.buildingNum" placeholder="请输入"></el-input>
          <el-select v-model="form.buildingNumUnit" style="width: 300px;" placeholder="请选择">
            <el-option
              v-for="item in dong"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
          <el-input v-model="form.roomNum" placeholder="请输入"></el-input>
          <el-select v-model="form.roomNumUnit" style="width: 300px;" placeholder="请选择">
            <el-option
              v-for="item in roomUnit"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="装修情况" prop="decorationLevel">
        <el-select v-model="form.decorationLevel" placeholder="请选择">
          <el-option
            v-for="item in renovation"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出租方式" prop="rentalForm" v-if="form.enableSell === 0 || (form.enableSell === 1 && form.houseType === 142)">
        <el-select v-model="form.rentalForm" placeholder="请选择">
          <el-option
            v-for="item in wayList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="面积" prop="area">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.area" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">㎡</span>
        </div>
      </el-form-item>
      <el-form-item label="户型" prop="huxing" v-if="![103,142].includes(form.houseType)">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.bedroomNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">房</span>
          <el-input v-model="form.saloonNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">厅</span>
          <el-input v-model="form.toiletNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">卫</span>
        </div>
      </el-form-item>
      <el-form-item label="合同期限" prop="term" v-if="form.enableSell === 0 && form.rentType === 2">
        <el-date-picker
          v-model="form.term"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="入住时间" prop="inTime" v-if="form.enableSell === 0 && ![103,142].includes(form.houseType)">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-checkbox v-model="checked" @change="changeChecked">随时入住</el-checkbox>
          <div style="width: 20px;"></div>
          <el-date-picker
            @change="checked = false"
            v-model="form.inTime"
            type="date"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="租金" prop="jiagezu" v-if="form.enableSell !== 1">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.price" type="number" placeholder="请输入"></el-input>
          <span style="margin: 0 10px; font-size: 13px; color: black;">元</span>
          <el-select v-model="form.payCycle" placeholder="请选择">
            <el-option
              v-for="item in yuefu"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="售价" prop="shou" v-if="form.rentType === 0 && form.enableSell !== 0">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.sellPrice" type="number" placeholder="请输入"></el-input>
          <span style="margin: 0 10px; font-size: 13px; color: black;">元</span>
          <el-select v-model="form.buyPayStyle" placeholder="请选择">
            <el-option
              v-for="item in anjie"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="楼层" prop="louceng">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.inFloorNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">层</span>
          <span style="margin: 0 10px; font-size: 13px; color: black;">共</span>
          <el-input v-model="form.floorNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">层</span>
          <el-select v-model="form.housePassType" style="width: 300px;" placeholder="请选择">
            <el-option
              v-for="item in elevator"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item :label="form.houseType === 103 ? '办公设施' : '房源设施'" prop="houseDeviceIds" v-if="(form.enableSell === 0 && [117,141,103].includes(form.houseType)) || (form.enableSell === 1 && [103,141,116].includes(form.houseType))">
        <el-select v-model="form.houseDeviceIds" multiple placeholder="请选择">
          <el-option
            v-for="item in device"
            :key="item.id"
            :label="item.deviceName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房源特色" prop="description">
        <el-input
          type="textarea"
          placeholder="写下你想说的，（地点优势，装修，配套显示房屋亮点）如交通信息，房源周边商圈，小区环境，家电家具设备，对租客的期待，请避免此处写联系方式引发骚扰，以上描述会影响你的房租金，你可得好好写哦。。。"
          v-model="form.description"
          rows="7"
        />
      </el-form-item>
      <el-form-item label="上传图片" prop="pic">
        <span style="font-size: 13px; color: #8c939d;">建议选择横版图片，图片建议6张，首张会作为封面图片</span>
        <el-upload
          :action="base"
          :limit="6"
          :before-upload="beforeUpluad"
          :on-exceed="onExceed"
          :file-list="fileList"
          list-type="picture-card"
          :on-success="onSuccess"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传视频" prop="video">
        <span style="font-size: 13px; color: #8c939d;">可选，视频时长不超过 60 秒</span>
        <el-upload
          accept=".mp4"
          :action="base1"
          :limit="1"
          :before-upload="beforeUpluad1"
          :on-exceed="onExceed1"
          :file-list="videoList"
          list-type="picture-card"
          :on-success="onSuccess1"
          :on-remove="handleRemove1">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="房源标签" prop="label">
        <el-select v-model="form.label" multiple placeholder="请选择">
          <el-option
            v-for="item in labels"
            :key="item.label"
            :label="item.label"
            :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" maxlength="11" type="number" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="是否公开联系方式" prop="publicContact">
        <el-switch
          v-model="form.publicContact"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
      </el-form-item>
    </el-form>
    <!-- 地图选点 -->
    <mapSelect @chooseOrgAddr="chooseOrgAddr" ref="ref"></mapSelect>
  </GlobalWindow>
</template>

<script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  import mapSelect from '@/components/common/map'
  import { fetchList } from '@/api/rentHouse/rhCityInfo'
  import { findByDictCode, getSubwayByTx } from '@/api/system/common'
  import { fetchList as houseDevice } from '@/api/rentHouse/rhHouseDeviceConfig'

  export default {
    name: 'OperaRhCityInfoWindow',
    extends: BaseOpera,
    components: { GlobalWindow, mapSelect },
    data () {
      const validate = (rule, value, callback) => {
        if (!this.form.buildingNum || !this.form.buildingNumUnit || !this.form.roomNum || !this.form.roomNumUnit) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      const huxing = (rule, value, callback) => {
        if (!this.form.bedroomNum || !this.form.saloonNum || !this.form.toiletNum) {
          callback(new Error('请输入完整的户型信息'));
        } else {
          callback();
        }
      };
      const jiagezu = (rule, value, callback) => {
        if (!this.form.price || !this.form.payCycle) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      const jiageshou = (rule, value, callback) => {
        if (!this.form.sellPrice || !this.form.buyPayStyle) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      const louceng = (rule, value, callback) => {
        if (!this.form.inFloorNum || !this.form.floorNum || !this.form.housePassType) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      return {
        base: process.env.VUE_APP_API_PREFIX + '/upload/image',
        base1: process.env.VUE_APP_API_PREFIX + '/upload/attach',
        checked: true,
        city: [],
        dong: [],
        roomUnit: [],
        types: [
          { label: '住宅', id: 117 },
          { label: '公寓', id: 141 },
          { label: '写字楼', id: 103 },
          { label: '商铺', id: 142 }
        ],
        yuefu: [],
        anjie: [],
        elevator: [],
        device: [],
        labels: [],
        renovation: [
          { name: '精装', id: 0 },
          { name: '简装', id: 1 },
          { name: '豪装', id: 2 },
          { name: '毛坯', id: 3 },
          { name: '不限', id: 4 }
        ],
        wayList: [
          { name: '整套房', id: 0 },
          { name: '单间房', id: 1 }
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < new Date().getTime()
          }
        },
        // 表单数据
        form: {
          id: null,
          cityId: '',
          rentType: 0,
          title: '',
          address: '',
          term: '',
          latitude: '',
          longitude: '',
          buildingNum: '',
          buildingNumUnit: '',
          roomNum: '',
          roomNumUnit: '',
          houseType: '',
          decorationLevel: '',
          rentalForm: '',
          area: '',
          bedroomNum: '',
          saloonNum: '',
          toiletNum: '',
          inTime: '',
          enableSell: 0,
          payCycle: '',
          buyPayStyle: '',
          price: '',
          sellPrice: '',
          inFloorNum: '',
          floorNum: '',
          housePassType: '',
          houseDeviceIds: [],
          description: '',
          pic: '',
          video: '',
          label: '',
          replyStyle: '0',
          phone: '',
          publicContact: 0,
          reviewStatus: 1,
          recentMetro: ''
        },
        fileList: [],
        videoList: [],
        roomUnit1: [],
        // 验证规则
        rules:  {
          cityId: [
            { required: true, message: '请选择城市', trigger: 'blur' }
          ],
          rentType: [
            { required: true, message: '请选择房源类型', trigger: 'blur' }
          ],
          term: [
            { required: true, message: '请选择合同期限', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请选择小区位置', trigger: 'blur' }
          ],
          roomV: [
            { required: true, validator: validate, trigger: 'blur' }
          ],
          houseType: [
            { required: true, message: '请选择房屋类型', trigger: 'blur' }
          ],
          decorationLevel: [
            { required: true, message: '请选择装修情况', trigger: 'blur' }
          ],
          rentalForm: [
            { required: true, message: '请选择出租方式', trigger: 'blur' }
          ],
          area: [
            { required: true, message: '请输入面积', trigger: 'blur' }
          ],
          huxing: [
            { required: true, validator: huxing, trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
          ],
          label: [
            { required: true, message: '请选择房源标签', trigger: 'blur' }
          ],
          pic: [
            { required: true, message: '请上传图片', trigger: 'blur' }
          ],
          description: [
            { required: true, message: '请输入房源特色', trigger: 'blur' }
          ],
          jiagezu: [
            { required: true, validator: jiagezu, trigger: 'blur' }
          ],
          shou: [
            { required: true, validator: jiageshou, trigger: 'blur' }
          ],
          louceng: [
            { required: true, validator: louceng, trigger: 'blur' }
          ],
          houseDeviceIds: [
            { required: true, message: '请选择房源设施', trigger: 'blur' }
          ]
        },
        cityList: []
      }
    },
    created () {
      this.config({
        api: '/rentHouse/rhHouse',
        'field.id': 'id'
      })
    },
    methods: {
      changeHouseType(e) {
        this.form.rentalForm = ''
        if (e === 142) {
          this.wayList = [
            { name: '临街门面', id: 0 },
            { name: '商场门面', id: 1 }
          ]
        } else {
          if (this.form.enableSell === 0 && e === 141) {
            this.wayList = [
              { name: '整套房', id: 0 }
            ]
          } else if (this.form.enableSell === 0 && this.form.rentType === 1 && e === 117) {
            this.wayList = [
              { name: '单间房', id: 1 }
            ]
          } else {
            this.wayList = [
              { name: '整套房', id: 0 },
              { name: '单间房', id: 1 }
            ]
          }
        }
      },
      changeEnableSell(e) {
        if (e === 1) {
          this.types = [
            { label: '住宅', id: 117 },
            { label: '公寓', id: 141 },
            { label: '别墅', id: 116 },
            { label: '写字楼', id: 103 },
            { label: '商铺', id: 142 }
          ]
        } else {
          this.types = [
            { label: '住宅', id: 117 },
            { label: '公寓', id: 141 },
            { label: '写字楼', id: 103 },
            { label: '商铺', id: 142 }
          ]
        }
      },
      changeType (e) {
        this.form.houseType = ''
        if (e === 1 && this.form.enableSell === 0) {
          this.types = [
            { label: '住宅', id: 117 }
          ]
        } else {
          this.types = [
            { label: '住宅', id: 117 },
            { label: '公寓', id: 141 },
            { label: '写字楼', id: 103 },
            { label: '商铺', id: 142 }
          ]
        }
      },
      open (title, target) {
        this.title = title
        this.fileList = []
        this.videoList = []
        this.form.buildingNum = ''
        this.form.buildingNumUnit = ''
        this.form.roomNum = ''
        this.form.roomNumUnit = ''
        this.form.title = ''
        this.form.address = ''
        this.form.inFloorNum = ''
        this.form.floorNum = ''
        this.form.housePassType = ''
        this.form.sellPrice = ''
        this.form.price = ''
        this.form.bedroomNum = ''
        this.form.saloonNum = ''
        this.form.toiletNum = ''
        this.visible = true
        this.getCity()
        this.getDevice()
        this.getfindByDictCode()
        // 新建
        if (target == null) {
          this.$nextTick(() => {
            this.$refs.form.resetFields()
            this.form[this.configData['field.id']] = null
          })
          return
        }
        // 编辑
        this.$nextTick(() => {
          for (const key in this.form) {
            this.form[key] = target[key]
          }
          if (this.form.houseType === 142) {
            this.wayList = [
              { name: '临街门面', id: 0 },
              { name: '商场门面', id: 1 }
            ]
          } else {
            this.wayList = [
              { name: '整套房', id: 0 },
              { name: '单间房', id: 1 }
            ]
          }
          let arr = this.form.pic.split(',')
          arr.forEach(item => {
            this.fileList.push({ url: process.env.VUE_APP_API_IMG + item })
          })
          this.checked = !this.form.inTime
        })
      },
      __confirmCreate () {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          // 调用新建接口
          this.isWorking = true
          let obj = JSON.parse(JSON.stringify(this.form))
          obj.label = obj.label.join(',')
          this.api.create(obj)
            .then(() => {
              this.visible = false
              this.$tip.apiSuccess('新建成功')
              this.$emit('success')
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        })
      },
      __confirmEdit () {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          // 调用更新接口
          this.isWorking = true
          let obj = JSON.parse(JSON.stringify(this.form))
          obj.label = obj.label.join(',')
          this.api.updateById(obj)
            .then(() => {
              this.visible = false
              this.$tip.apiSuccess('修改成功')
              this.$emit('success')
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        })
      },
      onSuccess(res) {
        this.fileList.push({ ...res.data, url: process.env.VUE_APP_API_IMG + res.data.accessUri })
        let arr = []
        this.fileList.forEach(item => {
          arr.push(item.accessUri)
        })
        this.form.pic = arr.join(',')
      },
      onSuccess1(res) {
        this.videoList.push({ ...res.data, url: process.env.VUE_APP_API_IMG + res.data.accessUri })
        this.form.video = res.data.accessUri
      },
      handleRemove(a, b) {
        this.fileList.forEach((item, index) => {
          if (item.accessUri === a.accessUri) {
            this.fileList.splice(index, 1)
          }
        })
        let arr = b.map(item => item.accessUri)
        this.form.pic = arr.join(',')
      },
      handleRemove1(a, b) {
        this.videoList = []
        this.form.video = ''
      },
      beforeUpluad1(file) {
        if ((file.size / 1024 / 1024) > 50) {
          this.$message.warning('上传视频大小不能超过50mb')
          return false
        }
        return true
      },
      beforeUpluad(file) {
        if ((file.size / 1024 / 1024) > 5) {
          this.$message.warning('上传图片大小不能超过5mb')
          return false
        }
        return true
      },
      onExceed() {
        this.$message.warning('最多只能上传6张图片')
      },
      onExceed1() {
        this.$message.warning('最多只能上传1个视频')
      },
      getDevice() {
        houseDevice({
          capacity: 9999,
          page: 1,
          model: {}
        }).then(res => {
          this.device = res.records
        })
      },
      changeChecked(e) {
        this.checked = e
        if (e) {
          this.form.inTime = ''
        }
      },
      getCity() {
        fetchList({
          capacity: 9999,
          page: 1,
          model: {
            display: 1
          }
        }).then(res => {
          this.cityList = res.records
        })
      },
      // 选取地理位置后的回调
      chooseOrgAddr(data){
        this.form.address = data.address
        this.form.title = data.name
        this.form.latitude = data.lat
        this.form.longitude = data.lng
        getSubwayByTx(`nearby(${data.lat},${data.lng},1000,1)`)
          .then(res => {
            let data = JSON.parse(res)
            if (data.status === 0) {
              let arr = []
              data.data.forEach(item => {
                if (item._distance <= 3000) {
                  arr.push(item.title)
                }
              })
              this.form.recentMetro = arr.join(',')
              console.log(this.form.recentMetro)
            }
          })
        this.$refs.ref.closeDialog()
      },
      getfindByDictCode() {
        // 栋
        findByDictCode({
          dictCode: 'sys_house_building'
        }).then(res => {
          if (res.length > 0) {
            this.dong = res
            this.form.buildingNumUnit = res[0].label
          }
        })
        // 单元
        findByDictCode({
          dictCode: 'sys_house_number'
        }).then(res => {
          if (res.length > 0) {
            this.roomUnit = res
            this.form.roomNumUnit = res[0].label
          }
        })
        // 房屋类型
        // findByDictCode({
        //   dictCode: 'sys_house_type'
        // }).then(res => {
        //   if (res.length > 0) {
        //     this.types = res
        //   }
        // })
        // 月付
        findByDictCode({
          dictCode: 'sys_house_yuefu'
        }).then(res => {
          if (res.length > 0) {
            this.yuefu = res
            this.form.payCycle = res[0].label
          }
        })
        // 按揭
        findByDictCode({
          dictCode: 'sys_house_anjie'
        }).then(res => {
          if (res.length > 0) {
            this.anjie = res
            this.form.buyPayStyle = res[0].label
          }
        })
        // 电梯
        findByDictCode({
          dictCode: 'sys_house_dianti'
        }).then(res => {
          if (res.length > 0) {
            this.elevator = res
            this.form.housePassType = res[0].label
          }
        })
        // 标签
        findByDictCode({
          dictCode: 'sys_house_label'
        }).then(res => {
          if (res.length > 0) {
            this.labels = res
          }
        })
        // 标签
        findByDictCode({
          dictCode: 'sys_house_room'
        }).then(res => {
          if (res.length > 0) {
            this.roomUnit1 = res
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #map {
    width: 100%;
    height: 300px;
    background: #8c939d;
  }
</style>
